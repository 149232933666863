<template>
  <div>
    <!-- Filters -->
    <DashboardFilter
      :date-filter.sync="dateFilter"
      :date-options="dateOptions"
      :date-start-filter.sync="dateStartFilter"
      :date-end-filter.sync="dateEndFilter"
    />
    <b-card
      no-body
      class="card-statistics"
    >
      <b-card-header>
        <b-card-title>Statistics</b-card-title>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <!-- Comps created -->
          <b-col
            md="4"
            sm="6"
            class="mb-2 mb-md-0 mb-md-2"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                  size="48"
                  variant="light-primary"
                >
                  <feather-icon
                    size="24"
                    icon="ClipboardIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ data.createdCompetitions }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Comps created
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <!-- Copy bet -->
          <!-- <b-col
            md="4"
            sm="6"
            class="mb-2 mb-md-0 mb-md-2"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                  size="48"
                  variant="light-info"
                >
                  <feather-icon
                    size="24"
                    icon="CopyIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ totalCopyBet }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Copy Bet
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col> -->
          <!-- Howls -->
          <!-- <b-col
            md="4"
            sm="6"
            class="mb-2 mb-md-0 mb-md-2"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                  size="48"
                  variant="light-danger"
                >
                  <feather-icon
                    size="24"
                    icon="TrelloIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ totalHowls }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Howls
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col> -->
          <!-- Rehowls -->
          <!-- <b-col
            md="4"
            sm="6"
            class="mb-2 mb-md-0 mb-md-2"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                  size="48"
                  variant="light-success"
                >
                  <feather-icon
                    size="24"
                    icon="ExternalLinkIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ totalRehowls }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Rehowls
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col> -->
          <!--Buy-in balance -->
          <b-col
            md="4"
            sm="6"
            class="mb-2 mb-md-0 mb-md-2"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                  size="48"
                  variant="light-warning"
                >
                  <feather-icon
                    size="24"
                    icon="GridIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  ${{ formatAmount(data.buyInBalance) }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Buy-in balance
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <!-- Deposit Balance -->
          <b-col
            md="4"
            sm="6"
            class="mb-2 mb-md-0 mb-md-2"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                  size="48"
                  variant="light-secondary"
                >
                  <feather-icon
                    size="24"
                    icon="LayersIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  ${{ formatAmount(data.depositBalance) }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Deposit Balance
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <!-- Payout -->
          <b-col
            md="4"
            sm="6"
            class="mb-2 mb-md-0 mb-md-2"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                  size="48"
                  variant="light-secondary"
                >
                  <feather-icon
                    size="24"
                    icon="SlashIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  ${{ formatAmount(data.payout) }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Payout
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <!-- Total Followers -->
          <!-- <b-col
            md="4"
            sm="6"
            class="mb-2 mb-md-0 mb-md-2"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                  size="48"
                  variant="light-primary"
                >
                  <feather-icon
                    size="24"
                    icon="UserPlusIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ totalFollower }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Followers
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col> -->
          <!-- Total User -->
          <b-col
            md="4"
            sm="6"
            class="mb-2 mb-md-0 mb-md-2"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                  size="48"
                  variant="light-success"
                >
                  <feather-icon
                    size="24"
                    icon="UserCheckIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ data.users }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Users
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card no-body>
      <b-card-body>
        <b-row>

          <h5 style="color: black; font-size: 18px;  margin-left: 21px; margin-top: 4px;">
            <span style="font-weight: bold;">FLEXI ROLL OVER WALLET:</span>   {{ formatCurrencyBuiltInFunc(flexiRollOverWallet) }}

          </h5>
        <!-- <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <v-select
            :value="dateFilter"
            :options="dateOptions"
            :reduce="val => val.value"
            @input="val => $emit('update:dateFilter', val)"
          />
        </b-col> -->

        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  formatAmount, convertTimeStart,
  convertTimeEnd, formatCurrencyBuiltInFunc,
} from '@/helpers/utils'
import useToastHandler from '@/services/toastHandler'
import timeFilter from '@core/utils/enum/date'
import { ref } from '@vue/composition-api'
import {
  BAvatar,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
  BRow,
} from 'bootstrap-vue'
import {
  previousMonday, startOfMonth, startOfQuarter, startOfYear, startOfDay, subDays, endOfDay,
} from 'date-fns'
import { mapActions, mapState } from 'vuex'
import DashboardFilter from './DashboardFilter'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    DashboardFilter,
    BMediaBody,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('dashboard/detail', ['data']),
    ...mapState('races/flexiRollOver', ['flexiRollOverWallet']),
  },
  watch: {
    dateFilter(val) {
      const now = new Date()
      const startOfToday = startOfDay(now).toISOString()
      const endOfToday = endOfDay(now).toISOString()
      const startOfYesterday = startOfDay(subDays(new Date(), 1)).toISOString()
      const endOfYesterday = endOfDay(subDays(new Date(), 1)).toISOString()
      const startOfThisWeek = startOfDay(previousMonday(new Date())).toISOString()
      const startOfThisMonth = startOfDay(startOfMonth(new Date())).toISOString()
      const startOfThisQuater = startOfDay(startOfQuarter(new Date())).toISOString()
      const startOfThisYear = startOfDay(startOfYear(new Date())).toISOString()

      // const today = formatDateValue(now)
      // // const mondayOfThisWeek = formatDateValue(previousMonday(new Date()))
      // const firstDayOfThisMonth = formatDateValue(startOfMonth(new Date()))
      // const firstDayOfThisQuater = formatDateValue(startOfQuarter(new Date()))
      // const firstDayOfThisYear = formatDateValue(startOfYear(new Date()))

      if (val) {
        this.dateStartFilter = null
        this.dateEndFilter = null
      }

      if (val === timeFilter.TODAY) {
        this.asyncDashboard({
          startDate: startOfToday,
          endDate: endOfToday,
        })
      } else if (val === timeFilter.YESTERDAY) {
        this.asyncDashboard({
          startDate: startOfYesterday,
          endDate: endOfYesterday,
        })
      } else if (val === timeFilter.THIS_WEEK) {
        this.asyncDashboard({
          startDate: startOfThisWeek,
          endDate: endOfToday,
        })
      } else if (val === timeFilter.THIS_MONTH) {
        this.asyncDashboard({
          startDate: startOfThisMonth,
          endDate: endOfToday,
        })
      } else if (val === timeFilter.THIS_QUARTER) {
        this.asyncDashboard({
          startDate: startOfThisQuater,
          endDate: endOfToday,
        })
      } else if (val === timeFilter.THIS_YEAR) {
        this.asyncDashboard({
          startDate: startOfThisYear,
          endDate: endOfToday,
        })
      } else if (val === timeFilter.CUSTOMIZE_TIME) {
        this.resetDataDashboard()
      }
    },
    dateStartFilter(val) {
      if (val && this.dateEndFilter) {
        this.asyncDashboard({
          startDate: this.convertTimeStart(this.dateStartFilter),
          endDate: this.convertTimeEnd(this.dateEndFilter),
        })
      }
    },
    dateEndFilter(val) {
      if (val && this.dateStartFilter) {
        this.asyncDashboard({
          startDate: this.convertTimeStart(this.dateStartFilter),
          endDate: this.convertTimeEnd(this.dateEndFilter),
        })
      }
    },
  },
  async created() {
    await this.getFlexiRollOverWallet()
    const now = new Date()
    const startOfToday = startOfDay(now).toISOString()
    const endOfToday = endOfDay(now).toISOString()
    await this.asyncDashboard({
      startDate: startOfToday,
      endDate: endOfToday,
    })
  },
  methods: {
    ...mapActions({
      asyncDashboard: 'dashboard/detail/load',
      resetDataDashboard: 'dashboard/detail/reset',
      getFlexiRollOverWallet: 'races/flexiRollOver/getFlexiRollOverWallet',
    }),
  },
  setup() {
    const { showToastError, showToastSuccess } = useToastHandler()
    const dateFilter = ref(1)
    const dateStartFilter = ref(null)
    const dateEndFilter = ref(null)

    const dateOptions = [
      { label: 'Today', value: 1 },
      { label: 'Yesterday', value: 2 },
      { label: 'This week', value: 3 },
      { label: 'This month', value: 4 },
      { label: 'This quarter', value: 5 },
      { label: 'This year', value: 6 },
      { label: 'Customize Time', value: 7 },
    ]

    return {
      dateFilter,
      dateOptions,
      dateStartFilter,
      dateEndFilter,

      showToastError,
      showToastSuccess,
      formatAmount,

      convertTimeStart,
      convertTimeEnd,
      formatCurrencyBuiltInFunc,
    }
  },
}
</script>
