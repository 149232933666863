<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2 d-flex align-items-center "
        >
          <h5 class="text-black">
            Filters
          </h5>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <v-select
            :value="dateFilter"
            :options="dateOptions"
            :reduce="val => val.value"
            @input="val => $emit('update:dateFilter', val)"
          />
        </b-col>
        <template v-if="dateFilter === timeFilter.CUSTOMIZE_TIME">
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2 d-flex align-items-center justify-content-start"
          >
            <label>From</label>
            <b-form-datepicker
              id="startdatepicker"
              v-model="start"
              class="mx-2"
              locale="en-US"
              :date-format-options="{ month: '2-digit', day: '2-digit', year: 'numeric' }"
              placeholder="Pick a day"
              @input="start => $emit('update:dateStartFilter', start)"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2 d-flex align-items-center justify-content-start"
          >
            <label>To</label>
            <b-form-datepicker
              id="enddatepicker"
              v-model="end"
              class="mx-2"
              locale="en-US"
              :date-format-options="{ month: '2-digit', day: '2-digit', year: 'numeric' }"
              placeholder="Pick a day"
              @input="end => $emit('update:dateEndFilter', end)"
            />
          </b-col>
        </template>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { numberProp, arrayProp, stringProp } from '@/helpers/props'
import timeFilter from '@core/utils/enum/date'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormDatepicker,
    vSelect,
  },
  props: {
    dateFilter: numberProp(),
    dateOptions: arrayProp(),
    dateStartFilter: stringProp(),
    dateEndFilter: stringProp(),
  },
  data() {
    return {
      timeFilter,
      start: '',
      end: '',
    }
  },
  watch: {
    dateFilter() {
      this.start = ''
      this.end = ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
button.vs__clear {
    display: none;
}
.text-black{
  color: #000!important;
}
</style>
